.App {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto;
}

* {
	margin: 0;
	padding: 0;
}

body {
	margin-top: 24px;
	font-family: "Open Sans";
}

h1 {
	font-family: "Anton SC";
	font-size: 24px;
	line-height: 32px;
}

h2 {
	font-family: "Open Sans";
	font-size: 16px;
	line-height: 24px;
}

h3 {
	font-family: "Open Sans";
	font-size: 16px;
	line-height: 32px;
}

p {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-align: left;
	color: #718096;
}

.holes {
	display: grid;
	grid-template-columns: repeat(3, 100px);
	grid-gap: 8px;
	justify-content: center;
	margin-top: 20px;
}

.hole {
	width: 100px;
	height: 100px;
	border: 2px solid #fafafa;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fafafa;
	cursor: pointer;
	position: relative;
}

.hole.disabled {
	pointer-events: none;
}

.mole {
	width: 100px;
	height: 100px;
	position: absolute;
}

.buttons {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
}

button {
	padding: 16px 80px;
	cursor: pointer;
	background: #73bda8;
	border-radius: 8px;
	border: none;
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
}

select {
	font-family: "Open Sans";
	font-size: 14px;
	line-height: 22px;
	font-weight: 600;
	box-sizing: border-box;
	border: 1px solid #e2e8f0;
	border-radius: 8px;
	padding: 2px 16px;
	width: 150px;
	margin-top: 8px;
	color: #1a1a1a;
	-webkit-appearance: none;
	-moz-appearance: none;
	transition: 500ms;
	text-align: left;
}

select:focus {
	outline: none !important;
	border: 1px solid #73bda8;
	transition: 500ms;
}

.stats {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 24px !important;
	margin: auto;

	font-family: "Open Sans";
	font-size: 20px;
	line-height: 32px;
	font-weight: 500;
	box-sizing: border-box;
	border: 1px solid #e2e8f0;
	border-radius: 8px;
	padding: 16px 16px;
	width: 343px;
}

.countdown {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 0px;
	gap: 2px;
}

.countdown p {
	text-align: right;
}

.countdown h2 {
	text-align: right;
}

.scorecard {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: left;
	padding: 0px;
	gap: 2px;
}

.selectlevels {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 8px !important;
	margin: auto;

	font-family: "Open Sans";
	font-size: 14px;
	line-height: 22px;
	font-weight: 500;
	box-sizing: border-box;
	border: 1px solid #e2e8f0;
	border-radius: 8px;
	padding: 8px 16px;
	width: 343px;
}

.footer {
	margin-top: 16px;
	display: flex;
	justify-content: center;
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 18px;
	text-align: left;
	color: #718096;
}

a {
	color: #73bda8;
	text-decoration: none;
}

.message {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px 24px;

	width: 327px;
	border-radius: 8px;
	background-color: #43aa8b;
	margin: 16px auto auto auto;
	color: white;
	position: fixed;
	top: 0;
	z-index: 1000;
	font-family: "Open Sans";
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	animation: slideDown 0.5s forwards, fadeOut 0.5s 5s forwards;
}

@keyframes slideDown {
	from {
		top: -50px;
	}
	to {
		top: 0;
	}
}

@keyframes fadeOut {
	from {
		top: 0;
	}
	to {
		top: -180px;
	}
}
